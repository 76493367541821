import '../scss/list-casinos.scss'

/**
 * Initializes event listeners for contact buttons and dynamically updates the contact modal
 * with the corresponding contact details when a button is clicked.
 *
 * The icons are created based on the `icon` property of each contact method in the `contactDetails` object.
 */
document.addEventListener('DOMContentLoaded', () => {
    const contactModal = document.querySelector('#contact-modal');
    const contactButtons = document.querySelectorAll('.btn-contact');

    contactButtons.forEach(button => button.addEventListener('click', () => {
        const contactDetails = JSON.parse(button.dataset.contact);
        const contactGrid = contactModal.querySelector('.list-casinos__contact-grid');

        contactGrid.innerHTML = '';

        Object.values(contactDetails).forEach(contactDetails => {
            const icon = document.createElement('i');
            icon.classList.add('bi', `bi-${contactDetails.icon}`);

            const wrapper = document.createElement('div');
            wrapper.classList.add('list-casinos__contact-item', 'text-center');
            wrapper.append(icon);

            contactGrid.append(wrapper);
        })
    }));
});

/**
 * Initializes event listeners for withdraw and dynamically updates the corresponding modals
 * with the related details and image when a button is clicked.
 *
 * This approach avoids code duplication by using a common function to handle both withdraws.
 */
document.addEventListener('DOMContentLoaded', () => {
    const withdrawsModal = document.querySelector('#withdrawalMethods');
    const withdrawsButtons = document.querySelectorAll('.btn-withdraws');

    withdrawsButtons.forEach(button => button.addEventListener('click', () => {
        const withdrawsDetails = JSON.parse(button.dataset.withdraws);
        const withdrawsImageSrc = button.dataset.src;
        const withdrawsList = withdrawsModal.querySelector('.withdrawal-methods__list');
        const withdrawsImageWrapper = withdrawsModal.querySelector('.withdrawal-methods__img');

        withdrawsList.innerHTML = '';
        withdrawsImageWrapper.innerHTML = '';

        Object.values(withdrawsDetails).forEach(withdrawsDetail => {
            const listElement = document.createElement('li');
            listElement.innerHTML = withdrawsDetail.title;

            withdrawsList.append(listElement);
        });

        const withdrawsImgElement = document.createElement('img');
        withdrawsImgElement.src = withdrawsImageSrc;
        withdrawsImgElement.width = 64;
        withdrawsImageWrapper.append(withdrawsImgElement);
    }));
});

/**
 * Initializes event listeners for deposit buttons and dynamically updates the corresponding modals
 * with the related details and image when a button is clicked.
 *
 * This approach avoids code duplication by using a common function to handle deposits logic.
 */
document.addEventListener('DOMContentLoaded', () => {
    const depositsModal = document.querySelector('#depositMethods');
    const depositsButtons = document.querySelectorAll('.btn-deposits');

    depositsButtons.forEach(button => button.addEventListener('click', () => {
        const depositDetails = JSON.parse(button.dataset.deposits);
        const depositsImageSrc = button.dataset.src;
        const depositsList = depositsModal.querySelector('.deposit-methods__list');
        const depositsImageWrapper = depositsModal.querySelector('.deposit-methods__img');

        depositsList.innerHTML = '';
        depositsImageWrapper.innerHTML = '';

        Object.values(depositDetails).forEach(depositDetail => {
            const listElement = document.createElement('li');
            listElement.innerHTML = depositDetail.title;

            depositsList.append(listElement);
        });

        const depositsImgElement = document.createElement('img');
        depositsImgElement.src = depositsImageSrc;
        depositsImgElement.width = 64;
        depositsImageWrapper.append(depositsImgElement);
    }));
});